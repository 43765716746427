import { payinStatus } from './payinStatus';

export const payinStatusFilter = {
  [payinStatus.RECEIVED]: 'Recebidos',
  [payinStatus.PENDING]: 'Pendentes',
  [payinStatus.CANCELED]: 'Cancelados',
  [payinStatus.REFUNDED]: 'Devolvidos',
  [payinStatus.UNAUTHORIZED]: 'Negados',
  [payinStatus.AUTHORIZED]: 'A receber'
};
