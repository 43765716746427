/**
 * https://github.com/transfeera/card-payin/blob/master/internal/services/transaction/entity.go#L100
 */
const rejectionReasons = [
  {
    code: 'amount_invalid',
    message: 'O valor do pagamento é inválido ou excede o valor permitido.'
  },
  {
    code: 'card_expired',
    message: 'Cartão vencido ou data de vencimento incorreta.'
  },
  {
    code: 'card_invalid',
    message: 'Dados do cartão são inválidos.'
  },
  {
    code: 'card_lost',
    message: 'O pagamento foi recusado porque o cartão foi declarado como perdido.'
  },
  {
    code: 'card_pin_invalid',
    message:
      'O PIN inserido está incorreto. O código de recusa de pagamento somente se aplica a pagamentos feitos com máquina de cartão.'
  },
  {
    code: 'card_pin_required',
    message: 'O cartão foi recusado, pois exige um PIN.'
  },
  {
    code: 'card_pin_attempts_exceeded',
    message: 'O limite de tentativas de informar o PIN foi ultrapassado.'
  },
  {
    code: 'card_security_code_invalid',
    message: 'O código de segurança está incorreto.'
  },
  {
    code: 'card_stolen',
    message: 'O pagamento foi recusado porque o cartão foi declarado como roubado.'
  },
  {
    code: 'payer_institution_not_available',
    message: 'Banco indisponível. Emissor sem comunicação.'
  },
  {
    code: 'payer_institution_transaction_error',
    message: 'Não foi possível processar a transação.'
  },
  {
    code: 'payer_balance_insufficient',
    message: 'O cartão não tem saldo suficiente para concluir a compra.'
  },
  {
    code: 'payer_limit_exceeded',
    message: 'O cliente excedeu o limite de valor da transação disponível no cartão.'
  },
  {
    code: 'processor_transaction_error',
    message: 'Não foi possível processar a transação.'
  },
  {
    code: 'processor_transaction_timeout',
    message: 'The transaction was not authorized because the communication time limit was exceeded.'
  },
  {
    code: 'suspected_of_fraud',
    message: 'Transação não autorizada. Suspeita de fraude.'
  },
  {
    code: 'merchant_registration_divergent',
    message: 'Existem divergências no cadastro do TID ou do MID.'
  }
];

/**
 * @param {import("@/types/payin").CardRejectionReason} code
 */
export const getRejectionReasonByCode = code => {
  return rejectionReasons.find(error => error.code === code);
};
