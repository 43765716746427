import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = config.url.api.payin + '/app/payment_links';

export const paymentLinkResource = {
  /**
   * @returns {Promise<{PaymentLinkResponse}>}
   */
  create: paymentData => {
    return fetch(`${BASE_URL}`, { method: 'POST', body: JSON.stringify({ ...paymentData }) });
  },
  list: ({ page, page_size }) => {
    const query = new URLSearchParams({ page, page_size });

    return fetch(`${BASE_URL}?${query}`, { method: 'GET' });
  },
  delete: paymentLinkId => {
    return fetch(`${BASE_URL}/${paymentLinkId}`, { method: 'DELETE' });
  }
};
