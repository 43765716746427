<template>
  <tr-autocomplete
    :label="label"
    display="name"
    :classes="classes"
    :name="name"
    :warning="warning"
    :hide-message-on-error="hideMessageOnError"
    :error="error"
    empty-result-message="Nenhum banco encontrado"
    open-on-focus="true"
    placeholder="Digite código ou nome"
    :required="required"
    error-message="Banco não preenchido! Busque o banco no campo acima"
    :get-data="getData"
    :value="value"
    :search-filter="searchBank"
    :on-select="onBankChange"
    :suggestion-component="showIspb ? bankAutocompleteSuggestionWithIspb : bankAutocompleteSuggestion"
  />
</template>

<script>
import { shallowRef } from 'vue';
import BankAutocompleteSuggestion from '@/commons/components/presentational/bank/BankAutocompleteSuggestion';
import BankAutocompleteSuggestionWithIspb from '@/commons/components/presentational/bank/BankAutocompleteSuggestionWithIspb';
import bankSearch from '@/commons/services/bank/search';
import getByProp from '@/commons/services/getByProp';

const bankAutocompleteSuggestion = shallowRef(BankAutocompleteSuggestion);
const bankAutocompleteSuggestionWithIspb = shallowRef(BankAutocompleteSuggestionWithIspb);

export default {
  name: 'bank-autocomplete-container',
  props: {
    label: {
      type: String,
      required: false
    },
    classes: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    bankId: {
      type: [String, Number],
      required: false
    },
    warning: {
      type: String,
      required: false
    },
    hideMessageOnError: {
      type: Boolean,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    getData: {
      type: Function,
      required: true
    },
    required: {
      type: Boolean,
      required: false
    },
    showIspb: {
      type: Boolean,
      required: false
    }
  },
  emits: ['change-bank'],
  data: () => ({
    bankAutocompleteSuggestion,
    bankAutocompleteSuggestionWithIspb,
    value: ''
  }),
  watch: {
    bankId(newVal) {
      this.setBankById(newVal);
    }
  },
  created() {
    this.setBankById(this.bankId);
  },
  methods: {
    onBankChange(value) {
      this.$emit('change-bank', value);
    },
    setBankById(bankId) {
      if (this._isBankIdValid(bankId)) {
        this.getData().then(banks => {
          this.value = getByProp.get({
            propValue: bankId,
            propName: 'id',
            list: banks
          });
        });
      }
      return bankId;
    },
    searchBank(query, bank) {
      return bankSearch.search(query, [bank], true);
    },
    _isBankIdValid(bankId) {
      return Boolean((typeof bankId !== 'object' && typeof bankId !== 'undefined') || String(bankId) === '0');
    }
  }
};
</script>
