<template>
  <dl>
    <bg-text
      is="dt"
      type="body-md"
    >
      {{
        {
          'pix': 'EndToEndID',
          'credit_card': 'Código de autorização',
          'boleto': 'Linha digitável'
        }[paymentMethodMacro]
      }}
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      class="with-copy"
    >
      <template v-if="id">
        {{ truncateIfNeed(id) }}
        <copy-clipboard-button
          type="inline"
          :text-to-copy="id"
        />
      </template>
      <template v-else>
        -
      </template>
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Payin ID
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      class="with-copy"
    >
      {{ truncateIfNeed(payinId) }}
      <copy-clipboard-button
        type="inline"
        :text-to-copy="payinId"
      />
    </bg-text>

    <template v-if="chargeId">
      <bg-text
        is="dt"
        type="body-md"
      >
        Charge ID
      </bg-text>
      <bg-text
        is="dd"
        type="body-md"
        class="with-copy"
      >
        {{ truncateIfNeed(chargeId) }}
        <copy-clipboard-button
          type="inline"
          :text-to-copy="chargeId"
        />
      </bg-text>
    </template>

    <bg-text
      is="dt"
      type="body-md"
    >
      Método
    </bg-text>
    <bg-text
      is="dd"
      v-tooltip.top-center="paymentMethodName"
      type="body-md"
      weight="bold"
    >
      {{ truncateIfNeed(paymentMethodName) }}
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Recebido em
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      weight="bold"
    >
      {{ receivedAt ? formatDateTime(receivedAt, formatDateTimeShape) : '-' }}
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Criado em
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      weight="bold"
    >
      {{ formatDateTime(createdAt, formatDateTimeShape) }}
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Vence em
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      weight="bold"
    >
      {{ dueDate ? formatDate(dueDate) : '-' }}
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Juros
    </bg-text>
    <bg-text
      is="dd"
      v-tooltip.top-center="interestType && interestModeTypes[interestType]({
        amount: interestAmount,
        percent: interestPercent
      })"
      type="body-md"
      weight="bold"
    >
      {{
        interestType
          ? truncateIfNeed(interestModeTypes[interestType]({
            amount: interestAmount,
            percent: interestPercent
          }))
          : '-'
      }}
    </bg-text>

    <bg-text
      is="dt"
      type="body-md"
    >
      Multa
    </bg-text>
    <bg-text
      is="dd"
      type="body-md"
      weight="bold"
    >
      {{
        fineType
          ? truncateIfNeed(typeFormatter[fineType]({
            amount: fineAmount,
            percent: finePercent
          }))
          : '-'
      }}
    </bg-text>

    <bg-text
      is="dt"
      v-if="paymentMethod === 'pix_key'"
      type="body-md"
    >
      {{ paymentMethodName }}
    </bg-text>
    <bg-text
      is="dd"
      v-if="paymentMethod === 'pix_key'"
      v-tooltip.top-center="pixKey"
      type="body-md"
      weight="bold"
    >
      {{ pixKey ? truncateIfNeed(pixKey) : '-' }}
    </bg-text>
  </dl>
</template>

<script setup>
import formatDate, { formatDateTime } from '@/commons/formatters/formatDate';
import formatDateTimeShape from '@/apps/payin/payins/constants/formatDateTimeShape';
import { middleTruncateWithEllipsis } from '@/commons/formatters/string';
import { replaceCommaByDotWithToFixed } from '@/commons/formatters/formatNumber';
import { centsToReal } from '@/commons/formatters/currency';
import { BgText } from '@transfeeradev/bridge';
import { usePaymentMethod } from '@/apps/payin/payins/components/usePaymentMethod';
import CopyClipboardButton from '@/commons/components/container/CopyClipboardButton.vue';

const props = defineProps({
  paymentMethod: {
    type: String,
    required: true
  },
  createdAt: {
    type: String,
    required: true
  },
  updatedAt: {
    type: String,
    required: true
  },
  dueDate: {
    type: String
  },
  pixKey: {
    type: String
  },
  interestType: {
    type: String
  },
  interestAmount: {
    type: Number
  },
  interestPercent: {
    type: Number
  },
  fineType: {
    type: String
  },
  fineAmount: {
    type: Number
  },
  finePercent: {
    type: Number
  },
  receivedAt: {
    type: String
  },
  id: {
    type: String
  },
  payinId: {
    type: String,
    required: true
  },
  chargeId: {
    type: String
  }
});

const MAX_STRING_LENGTH = 20;

const truncateIfNeed = string =>
  string.length > MAX_STRING_LENGTH ? middleTruncateWithEllipsis(string, MAX_STRING_LENGTH) : string;

const interestModeTypes = {
  fixed_per_day: values => `${centsToReal(values.amount)} ao dia`,
  percentage_per_day: values => `${replaceCommaByDotWithToFixed(values.percent, 2)}% ao dia`,
  percentage_per_month: values => `${replaceCommaByDotWithToFixed(values.percent, 2)}% ao mês`,
  percentage_per_year: values => `${replaceCommaByDotWithToFixed(values.percent, 2)}% ao ano`,
  fixed_per_working_day: values => `${centsToReal(values.amount)} por dia útil`,
  percentage_per_working_day: values => `${replaceCommaByDotWithToFixed(values.percent, 2)}% por dia útil`,
  percentage_per_month_working_days: values =>
    `${replaceCommaByDotWithToFixed(values.percent, 2)}% por dias úteis do mês`,
  percentage_per_year_working_days: values =>
    `${replaceCommaByDotWithToFixed(values.percent, 2)}% por dias úteis do ano`
};

const typeFormatter = {
  fixed: values => `${centsToReal(values.amount)}`,
  percentage: values => `${replaceCommaByDotWithToFixed(values.percent, 2)}%`
};

const { paymentMethodMacro, paymentMethodName } = usePaymentMethod(props.paymentMethod);
</script>

<style lang="postcss" scoped>
dl {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(--bg-spacing-md);
}

dt,
dd {
  margin: var(--bg-spacing-2x-sm) 0;
  display: flex;
  align-items: center;
}

dd {
  justify-self: flex-end;
}

dd.with-copy {
  position: relative;
}

dd :deep(button) {
  position: absolute;
  right: -36px;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
