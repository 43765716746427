<template>
  <bg-icon
    v-tooltip="paymentMethodName"
    :name="icon"
    size="sm"
    :aria-label="paymentMethodName"
  />
</template>

<script setup>
import { usePaymentMethod } from '@/apps/payin/payins/components/usePaymentMethod';
import { BgIcon } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  paymentMethod: {
    required: true,
    type: String
  }
});

const { paymentMethodMacro, paymentMethodName } = usePaymentMethod(props.paymentMethod);

const icon = computed(
  () =>
    ({
      pix: 'pix',
      boleto: 'receipt-long',
      credit_card: 'credit-card'
    }[paymentMethodMacro.value])
);
</script>
