<template>
  <bg-button
    v-if="type === 'inline'"
    :icon="icon"
    variant="ghost"
    size="sm"
    :aria-label="label"
    @click="copyToClipboard"
  />
  <bg-button
    v-else
    :right-icon="icon"
    @click="copyToClipboard"
  >
    {{ text }}
  </bg-button>
</template>

<script setup>
import { BgButton } from '@transfeeradev/bridge';
import { computed } from 'vue';
import { ref } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: 'Copiar'
  },
  copiedText: {
    type: String,
    default: 'Copiado!'
  },
  textToCopy: {
    type: String
  },
  type: {
    type: String,
    validator: value => ['inline', 'default'].includes(value)
  }
});

const initialIcon = computed(() => (props.type === 'inline' ? 'copy' : undefined));
const icon = ref(initialIcon.value);
const text = ref(props.label);

const copyToClipboard = () => {
  navigator.clipboard.writeText(props.textToCopy);
  icon.value = 'check';
  text.value = props.copiedText;

  setTimeout(() => {
    icon.value = initialIcon.value;
    text.value = props.label;
  }, 1000);
};
</script>

<style scoped>
@keyframes check {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

:deep(svg) {
  animation: check 0.5s;
}
</style>
