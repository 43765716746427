<template>
  <link-description
    v-if="isStatus([entryType.BATCH, entryType.REFUND])"
  />

  <template v-else-if="item.complement.isPix">
    <deposit-description
      v-if="isStatus([entryType.DEPOSIT])"
    />
    <deposit-refund-description
      v-else-if="isStatus([
        entryType.DEPOSIT_REFUND,
        entryType.PENDING_DEPOSIT_REFUND,
        entryType.CANCELLED_DEPOSIT_REFUND
      ])"
    />
  </template>

  <tr-typography v-else-if="item.complement.bank">
    {{ breakBiggerWord(item.description, 19) }}
  </tr-typography>

  <tr-typography v-else-if="isStatus([entryType.BLOCKED, entryType.BLOCKED_REFUND, entryType.UNLOCKED])">
    {{ breakBiggerWord(item.description, 44) }}
  </tr-typography>
</template>

<script lang="jsx" setup>
import { computed } from 'vue';
import breakBiggerWord from '@/commons/formatters/breakBiggerWord';
import { statement } from '@transfeeradev/api-enums';

const { entryType } = statement;

const emit = defineEmits(['click-pix', 'click']);

const props = defineProps({
  item: {
    required: false,
    type: Object
  }
});

const isStatus = statuses => {
  return statuses.includes(props.item.type);
};

const pixThirdParty = computed(() => {
  if (props.item.type === statement.entryType.DEPOSIT) {
    return props.item.complement.payer;
  }

  return props.item.complement.receiver;
});

const LinkDescription = () => {
  return (
    <tr-button
      onButtonClick={() => emit('click', props.item)}
      variant="text"
      linkColor={true}
      textVariant="body"
      label={breakBiggerWord(props.item.description, 50)}
    />
  );
};

const DepositDescription = () => {
  return (
    <>
      Pix de{' '}
      <tr-typography classes="cursor-pointer blue" onMouseup={() => emit('click-pix', props.item)}>
        {breakBiggerWord(pixThirdParty.value.name, 13)}
      </tr-typography>
    </>
  );
};

const DepositRefundDescription = () => {
  return (
    <>
      Devolução de Pix de{' '}
      <tr-typography classes="cursor-pointer blue" onMouseup={() => emit('click-pix', props.item)}>
        {breakBiggerWord(pixThirdParty.value.name, 20)}
      </tr-typography>
    </>
  );
};
</script>
