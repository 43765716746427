<template>
  <tr-autocomplete
    ref="receiverAutocomplete"
    :label="label"
    display="cpf_cnpj"
    name="cpfCnpj"
    :hint="false"
    :classes="`receivers-autocomplete col-lg-5 ${classes}`"
    open-on-focus="true"
    :no-margin="noMargin"
    :minimum-chars-to-search="3"
    :error-message="errorMessage || 'Dados incorretos'"
    :get-data="getDestinationBankAccounts"
    :value="destinationBankAccount"
    :on-select="selectReceiver"
    :placeholder="placeholder || 'Busque por nome, documento ou chave Pix do recebedor'"
    :add-new-item-on-blur="true"
    :suggestion-component="ReceiversAutocompleteSuggestion"
    :suggestions-header-component="ReceiversAutocompleteSuggestionsHeader"
    :suggestions-footer-component="ReceiversAutocompleteSuggestionsFooter"
    add-button-color="blue"
    :add-button-top="true"
    :add-new-item-callback="() => {}"
  />
</template>

<script>
import ReceiversAutocompleteSuggestion from '@/apps/payments/payments/receivers/components/presentational/ReceiversAutocompleteSuggestion.vue';
import ReceiversAutocompleteSuggestionsHeader from '@/apps/payments/payments/receivers/components/presentational/ReceiversAutocompleteSuggestionsHeader.vue';
import ReceiversAutocompleteSuggestionsFooter from '@/apps/payments/payments/receivers/components/presentational/ReceiversAutocompleteSuggestionsFooter.vue';
import receiversResource from '@/apps/payments/payments/receivers/resources/receivers';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import masks from '@/commons/constants/masks';
import { shallowRef } from 'vue';

export default {
  name: 'receivers-autocomplete-container',
  props: [
    'onReceiverSelect',
    'label',
    'name',
    'required',
    'destinationBankAccount',
    'placeholder',
    'errorMessage',
    'classes',
    'noMargin',
    'autoFocus'
  ],
  data: () => ({
    ReceiversAutocompleteSuggestion: shallowRef(ReceiversAutocompleteSuggestion),
    ReceiversAutocompleteSuggestionsHeader: shallowRef(ReceiversAutocompleteSuggestionsHeader),
    ReceiversAutocompleteSuggestionsFooter: shallowRef(ReceiversAutocompleteSuggestionsFooter),
    masks
  }),
  mounted() {
    if (this.autoFocus) {
      this.$refs.receiverAutocomplete.focus();
    }
  },
  methods: {
    getDestinationBankAccounts(query) {
      return receiversResource.getAutocompleteSuggestions(query);
    },
    selectReceiver(receiver) {
      if (receiver && receiver.id) {
        receiversResource.get(receiver.id).then(receiverFound => {
          checkpointResource.create(checkpoint.DBA_AUTOCOMPLETE_SELECTED);
          this.onReceiverSelect(receiverFound);
        });
      }
    }
  }
};
</script>

<style scoped>
.receivers-autocomplete :deep(.receivers-autocomplete-suggestions-header) {
  margin-top: 16px;
  margin-bottom: 8px;
}

.receivers-autocomplete :deep(.receivers-autocomplete-suggestions-header label) {
  font-size: 0.9rem;
}
.receivers-autocomplete :deep(.autocomplete-dropdown) {
  min-width: 700px;
  width: 100%;
}
</style>
