<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="payin-details-dialog"
    title="Detalhes da cobrança"
    :has-its-own-buttons="true"
  >
    <div
      :key="key"
      class="modal-container center-h-and-v block"
    >
      <tr-loading
        v-if="loading"
        classes="center-h-and-v width-100"
      />
      <div
        v-else
        class="payin-details-dialog"
      >
        <status-badge
          :status="payin.status"
          badge-size="md"
          icon-size="sm"
        />
        <bg-text
          v-if="payin.payer?.name"
          type="body-md"
          color="grey-dark"
          weight="bold"
        >
          {{ payin.payer.name }}
        </bg-text>
        <bg-text
          v-if="payin.payer?.tax_id"
          type="body-md"
          color="grey-semi-dark"
          weight="bold"
        >
          {{ obfuscateAndFormatCPF(payin.payer.tax_id) }}
        </bg-text>
        <bg-text
          type="h4"
          color="green-semi-dark"
          weight="bold"
        >
          {{ centsToReal(payin.amount) }}
        </bg-text>
        <div class="payin-details-dialog__button-wrapper">
          <bg-button
            :aria-label="refundButtonStatus(payin)"
            left-icon="back-arrow"
            size="sm"
            :disabled="!isRefundable"
            @click.stop="() => $emit('refund-click', payin)"
          >
            Devolver
          </bg-button>
        </div>
        <div
          v-if="refunds.refunds?.length"
          class="payin-details-dialog__refund"
        >
          <div class="payin-details__refund-title">
            <bg-icon
              size="sm"
              name="back-arrow"
              color="grey-dark"
            />
            <bg-text
              type="body-sm"
              weight="bold"
              color="grey-dark"
            >
              Devoluções
            </bg-text>
          </div>
          <div
            v-for="(refund, index) in refunds.refunds"
            :key="refund.id"
            class="payins-details-dialog__refund-item"
          >
            <bg-text
              type="body-sm"
              color="grey-dark"
            >
              {{ centsToReal(refund.amount) }} em {{ formatDate(refund.created_at) }}
            </bg-text>
            <div
              v-if="isErrorVisible(refund, refunds.refunds?.length, index)"
              class="payins-details-dialog__refund-error"
            >
              <bg-icon
                size="sm"
                name="alert-outline"
                color="red-semi-dark"
              />
              <bg-text
                type="body-sm"
                color="red-semi-dark"
              >
                Ocorreu um erro nessa tentativa de cobrança
              </bg-text>
            </div>
          </div>
        </div>
        <div
          v-if="isCanceled"
          class="payin-details-dialog__canceled-text"
        >
          <bg-icon
            size="sm"
            name="close"
            color="grey-dark"
          />
          <bg-text
            type="body-sm"
            weight="bold"
            color="grey-dark"
          >
            <!-- TODO: map cancel motivation to print after comman ','-->
            Essa cobrança foi cancelada
          </bg-text>
        </div>
        <info-action-box
          v-if="payin.rejectionReason?.message"
          theme="bridge"
          type="error"
        >
          <bg-text type="body-sm">
            {{ payin.rejectionReason.message }}
          </bg-text>
          <bg-text type="label">
            Código: {{ payin.rejectionReason.code }}
          </bg-text>
        </info-action-box>
        <hr class="payin-details-dialog__hr">
        <bg-text
          type="body-md"
          color="grey-dark"
          weight="bold"
        >
          Mais detalhes
        </bg-text>
        <payin-more-details-list
          :id="id"
          class="payins-details-dialog__more-details"
          :fine-type="payin.details?.fine_type"
          :fine-amount="payin.details?.fine_amount"
          :fine-percent="payin.details?.fine_percent"
          :interest-type="payin.details?.interest_type"
          :interest-amount="payin.details?.interest_amount"
          :interest-percent="payin.details?.interest_percent"
          :pix-key="payin.details?.pix_key"
          :payment-method="payin.payment_type"
          :created-at="payin.created_at"
          :updated-at="payin.updated_at"
          :received-at="payin.received_at"
          :due-date="payin.details?.due_date"
          :charge-id="payin.details?.charge_id"
          :payin-id="payin.id"
        />
      </div>
    </div>
  </tr-dialog>
</template>

<script setup>
import { computed, ref } from 'vue';
import StatusBadge from '@/apps/payin/components/StatusBadge.vue';
import { BgButton, BgIcon, BgText } from '@transfeeradev/bridge';
import formatDate from '@/commons/formatters/formatDate';
import { obfuscateAndFormatCPF } from '@/commons/formatters/cpfCnpj';
import { centsToReal } from '@/commons/formatters/currency';
import { payinStatus } from '@/apps/payin/payins/constants/payinStatus';
import { refundButtonStatus } from '@/apps/payin/payins/constants/refundButtonStatus';
import { getPayinById, listRefunds } from '@/apps/payin/payins/services/payins';
import PayinMoreDetailsList from '@/apps/payin/payins/components/PayinMoreDetailsList.vue';
import { useDelayedLoading } from '@/commons/composables/useDelayedLoading';
import InfoActionBox from '@/commons/components/presentational/InfoActionBox.vue';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { usePaymentMethod } from '@/apps/payin/payins/components/usePaymentMethod';

defineProps({
  onOpenRefundDialog: {
    type: Function
  }
});
defineEmits(['refund-click']);

const key = ref();
const modal = ref();
const loading = ref(true);
const { handleError } = useErrorHandler();
const /** @type {Payin} */ payin = ref({});
const /** @type {PaginatedResponse<Refund>} */ refunds = ref([]);

const open = id => {
  const { delayLoading } = useDelayedLoading({ loading });

  payin.value = {};
  delayLoading(Promise.all([fetchPayinById(id), fetchRefunds(id)]), () => {
    modal.value.open();
  });
};

const close = () => {
  modal.value.close();
};

const isErrorVisible = (refund, refundsLength, index) => refund?.error && index + 1 === refundsLength;

const fetchPayinById = async id => {
  try {
    const response = await getPayinById(id);
    payin.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar o recebimento');
  }
};

const fetchRefunds = async id => {
  try {
    const response = await listRefunds(id);
    refunds.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar o recebimento');
  }
};
const isRefundable = computed(() => payin.value.refundable_amount > 0);
const isCanceled = computed(() => payin.value.status === payinStatus.CANCELED);
const paymentType = computed(() => payin.value.payment_type);

const { paymentMethodMacro } = usePaymentMethod(paymentType);

const id = computed(() => {
  if (paymentMethodMacro.value === 'pix') {
    return payin.value.end_to_end_id;
  }

  if (paymentMethodMacro.value === 'boleto') {
    return payin.value.details?.digitable_line;
  }

  if (paymentMethodMacro.value === 'credit_card') {
    return payin.value.details?.authorization_code;
  }

  return '';
});

defineExpose({
  open,
  close
});
</script>

<style lang="postcss" scoped>
.payin-details-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--bg-spacing-sm);
}

.payin-details-dialog__button-wrapper {
  display: flex;
  gap: 8px;
}

.payin-details-dialog__canceled-text {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: var(--bg-spacing-sm);
  padding: var(--bg-spacing-sm);
  background-color: var(--bg-bg-background);
  border-radius: var(--bg-border-radius-md);

  p {
    margin-left: var(--bg-spacing-x-sm);
  }
}

.payin-details-dialog__refund {
  width: 100%;
  margin: var(--bg-spacing-sm) 0 var(--bg-spacing-sm) 0;
  align-self: flex-start;
  background-color: var(--bg-bg-background);
  border-radius: var(--bg-border-radius-md);
  padding: 8px;
}

.payins-details-dialog__refund-item {
  & > p {
    margin-left: 24px;
  }
}

.payin-details-dialog__hr {
  border: 1px solid var(--bg-grey-light);
  opacity: 0.7;
  width: 100%;
  margin: 0;
}

.payins-details-dialog__more-details {
  min-width: 350px;
  max-width: 400px;
}

.payin-details__refund-title {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-x-sm);
}

.payins-details-dialog__refund-error {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-2x-sm);
  margin: 0 0 var(--bg-spacing-x-sm) 24px;
}

.flex {
  display: flex;
}

.modal-container {
  min-height: 400px;
}
</style>
