<template>
  <bg-badge
    :color="theme.badgeColor"
    size="sm"
  >
    <bg-icon
      v-if="theme.icon"
      size="sm"
      :name="theme.icon"
      :color="theme.textColor"
      :class="$style.icon"
    />
    <bg-text
      type="label"
      weight="semi-bold"
      :class="$style.label"
      :color="theme.textColor"
    >
      {{ theme.label }}
    </bg-text>
  </bg-badge>
</template>

<script setup>
import { BgIcon, BgBadge, BgText } from '@transfeeradev/bridge';
import moment from '@transfeeradev/moment';
import { computed } from 'vue';

const props = defineProps({
  status: {
    type: String,
    required: true
  },
  expiresAt: {
    type: String
  }
});

const isExpired = computed(() => {
  return props.expiresAt ? moment(props.expiresAt, 'YYYY-MM-DD').isBefore(moment()) : false;
});

const status = computed(() => {
  if (props.status === 'paid') {
    return 'paid';
  }

  return isExpired.value ? 'expired' : 'active';
});

const theme = computed(() => {
  return {
    paid: { label: 'Pago', textColor: 'green-semi-dark', badgeColor: 'green-light', icon: 'check', width: '8px' },
    expired: { label: 'Expirado', textColor: 'grey-semi-dark', badgeColor: 'grey-light', icon: 'info', width: '8px' },
    active: {
      label: 'Ativo',
      textColor: 'green-semi-dark',
      badgeColor: 'green-light',
      icon: 'payment-link',
      width: '16px'
    }
  }[status.value];
});
</script>

<style scoped module>
.label {
  margin-left: var(--bg-spacing-2x-sm);
}

.icon {
  width: v-bind('theme.width');
}
</style>
