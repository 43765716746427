<template>
  <teleport to="#app-notification">
    <tr-alert-notification
      v-if="hasScheduledMaintenance"
      role="alert"
      :full-width="true"
      type="warning"
    >
      {{ message }} - Mais detalhes
      <a
        target="_blank"
        href="https://materiais.transfeera.com/comunicado-manutencao"
      >na página</a>.
    </tr-alert-notification>
    <tr-alert-notification
      v-else-if="hasIncident"
      role="alert"
      :full-width="true"
      type="warning"
    >
      {{ message }} - Mais detalhes na
      <a
        target="_blank"
        :href="eventHref || 'https://status.transfeera.com/'"
      >Status Page</a>
    </tr-alert-notification>
  </teleport>
</template>

<script>
import statusPageAlerts from '@/commons/resources/statusPageAlerts';
import moment from '@transfeeradev/moment';

export default {
  name: 'status-page-incident-notification-container',
  data: () => ({
    isSandbox: process.env.VUE_APP_MODE === 'sandbox',
    message: '',
    eventHref: '',
    hasIncident: false,
    hasScheduledMaintenance: false
  }),
  created() {
    if (this.isSandbox) {
      return;
    }

    this.check();
  },
  methods: {
    async check() {
      this.hasScheduledMaintenance = await this.checkScheduledMaintenances();

      if (!this.hasScheduledMaintenance) {
        this.hasIncident = await this.checkIncidents();
      }
    },
    async checkIncidents() {
      const { incidents } = await statusPageAlerts.getIncidents();

      const activeIncident = incidents.find(incident => !incident.resolved_at);
      if (!activeIncident) {
        return false;
      }

      this.setMessage(activeIncident.name);
      this.eventHref = activeIncident.shortlink || this.eventHref;
      return true;
    },
    async checkScheduledMaintenances() {
      const [upcoming, active] = await Promise.all([
        statusPageAlerts.getUpcomingScheduledMaintenances(),
        statusPageAlerts.getActiveScheduledMaintenances()
      ]);

      const event = active?.scheduled_maintenances?.[0] || upcoming?.scheduled_maintenances?.[0];
      if (event) {
        if (event.scheduled_for && event.scheduled_until) {
          const startDate = moment(event.scheduled_for);
          const endDate = moment(event.scheduled_until);

          this.setMessage(
            `${event.name}, agendada para ${startDate.format('DD/MM/YYYY (dddd)')}, entre às ${startDate.format(
              'HH:mm'
            )} e ${endDate.format('HH:mm')}.`
          );
        } else {
          this.setMessage(event.name);
        }

        return true;
      }

      return false;
    },
    setMessage(fullMessage) {
      this.message = fullMessage.slice(0, 120);
      if (this.message.length < fullMessage.length) {
        this.message += '...';
      }
    }
  }
};
</script>
