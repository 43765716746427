<template>
  <tr-page-section
    title="Dados pessoais"
    :no-margin="true"
    class="vertical-offset3"
  >
    <div class="row">
      <tr-text-field
        ref="nameField"
        v-model="bankAccount.name"
        label="Nome completo (opcional)"
        name="name"
        classes="col-sm-12 col-md-6 col-lg-5"
        :pattern="regexes.FIRST_NAME"
        :ignore-error-on-dirty="true"
        error-message="O nome deve ter no mínimo 3 letras"
      />

      <cpf-cnpj-field-container
        ref="documentField"
        v-model:cpf-cnpj="bankAccount.cpf_cnpj"
        label="CPF/CNPJ"
        name="cpf_cnpj"
        classes="col-sm-12 col-md-6 col-lg-4"
        :on-change="value => (bankAccount.cpf_cnpj = value)"
        error-message="CPF ou CNPJ inválido"
      />
    </div>
  </tr-page-section>
  <tr-page-section
    title="Dados bancários"
    :no-margin="true"
    class="vertical-offset3"
  >
    <div class="row">
      <bank-autocomplete
        label="Banco"
        placeholder="Digite nome, código bancário ou ISPB"
        name="bank"
        classes="col-sm-12 col-md-6 col-lg-4"
        :required="true"
        :bank-id="bankAccount.bank_id"
        :show-ispb="true"
        @update:bank="onChangeBank"
      />
      <tr-text-field
        ref="agency"
        v-model="bankAccount.agency"
        label="Agência"
        name="agency"
        classes="col-sm-12 col-md-6 col-lg-2"
        :required="validations.agency.required"
        :pattern="agencyPattern"
        :error-message="validations.agency.errorMessage"
        :maxlength="agencyMaxDigits"
        :on-bank-change="onChangeAgency"
      />
      <tr-text-field
        ref="agency_digit"
        v-model="bankAccount.agency_digit"
        label="Dígito (opcional)"
        name="agency_digit"
        classes="col-sm-12 col-md-6 col-lg-2"
        input-classes="input-width--xs"
        :pattern="validations.agency.digit.pattern"
        :error-message="validations.agency.digit.errorMessage"
        :maxlength="validations.agency.digit.maxLength || validations.agency.digit.exactLength"
      />
    </div>
    <div class="row">
      <tr-text-field
        ref="account_type"
        v-model="bankAccount.account_type"
        label="Tipo da conta"
        name="account_type"
        :run-on-change-on-watch="true"
        classes="col-sm-12 col-md-6 col-lg-3 bottom-offset2"
        :error-to-force-until-is-dirty="errorField === 'account_type'"
        :error-message="validations.accountType.errorMessage"
        select="true"
        :required="true"
        :on-value-change="onChangeAccountType"
      >
        <option
          disabled
          selected
          value=""
        >
          Escolha um tipo de conta
        </option>
        <option
          v-for="accountTypeOption in accountTypes"
          :key="accountTypeOption"
          :selected="accountTypeOption === bankAccount.account_type"
          :value="accountTypeOption"
        >
          {{ accountType[accountTypeOption] }}
        </option>
      </tr-text-field>
      <tr-text-field
        ref="account"
        v-model="bankAccount.account"
        :label="`Conta ${accountTypeLastName[bankAccount.account_type] || ''}`"
        name="account"
        classes="col-sm-12 col-md-6 col-lg-3"
        :required="validations.account.required"
        :pattern="validations.account.pattern"
        :error-message="validations.account.errorMessage"
        :maxlength="accountMaxDigits"
      />
      <tr-text-field
        ref="account_digit"
        v-model="bankAccount.account_digit"
        label="Dígito"
        name="account_digit"
        classes="col-sm-12 col-md-6 col-lg-2"
        input-classes="input-width--xs"
        :required="validations.account.digit.required"
        :pattern="validations.account.digit.pattern"
        :error-message="validations.account.digit.errorMessage"
        :maxlength="validations.account.digit.maxLength || validations.account.digit.exactLength"
      />
    </div>
  </tr-page-section>
</template>

<script>
import regexes from '@transfeeradev/api-enums/regexes';
import BankAutocomplete from '@/apps/contacerta/validations/components/presentational/BankAutocomplete';
import accountType from '@/commons/constants/accountType';
import accountTypeLastName from '@/commons/constants/accountTypeLastName';
import CpfCnpjFieldContainer from '@/commons/components/container/CpfCnpjFieldContainer';

export default {
  name: 'validation-account-form',
  components: { CpfCnpjFieldContainer, BankAutocomplete },
  props: {
    bankAccount: {
      type: Object,
      required: true
    },
    validations: {
      type: Object
    },
    errorField: {
      type: String
    },
    accountTypes: {
      type: Array
    },
    agencyPattern: {
      type: RegExp
    },
    accountMaxDigits: {
      type: Number
    },
    agencyMaxDigits: {
      type: Number
    }
  },
  emits: ['update:agency', 'update:account-type', 'update:bank'],
  data: () => ({
    regexes,
    accountType,
    accountTypeLastName
  }),
  methods: {
    onChangeBank(bank) {
      this.bankAccount.bank_id = bank.id;
      this.$emit('update:bank', bank.id);
    },
    onChangeAgency(value) {
      this.$emit('update:agency', value);
    },
    onChangeAccountType(value) {
      this.$emit('update:account-type', value);
    },
    setAsInvalid(field) {
      if (this.$refs[field]) {
        this.$refs[field].setAsInvalid();
      }
    }
  }
};
</script>
