export function appendQueryString(query = new URLSearchParams(), filtersObj) {
  for (const [key, value] of Object.entries(filtersObj)) {
    if (Array.isArray(value)) {
      for (let filter of value) {
        query.append(key, filter);
      }
    } else if (value) {
      query.append(key, value);
    }
  }

  return query;
}
