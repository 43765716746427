import PayinsLayout from '@/apps/payin/Layout';
import PayinTermsOfUsePage from '@/apps/payin/PayinTermsOfUsePage.vue';
import PayinsPage from '@/apps/payin/payins/pages/PayinsPage.vue';
import PaymentLinkPage from '@/apps/payin/payment-link/pages/PaymentLinkPage.vue';
import PaymentLinkListPage from '@/apps/payin/payment-link/pages/PaymentLinkListPage.vue';
import CardReceivablesListPage from '@/apps/payin/card-receivables/pages/CardReceivablesListPage.vue';

import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import settingsResource from '@/commons/resources/settings';
import loggedUser from '@/commons/resources/loggedUser';
import { APPS } from '@/commons/constants/apps';

export default {
  path: '/recebimentos',
  component: PayinsLayout,
  name: 'Payins',
  redirect: {
    name: 'PayinsPage'
  },
  beforeEnter: () => {
    checkpointResource.create(checkpoint.PAYIN_PAGE_VIEWED);
  },
  children: [
    {
      path: 'termos-de-uso',
      component: PayinTermsOfUsePage,
      name: 'PayinTermsOfUsePage',
      meta: {
        async beforeEnter() {
          const settings = await settingsResource.get();
          const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
            checkpoint.CREDIT_CARD_ACCEPTED_TERMS_OF_USE
          );

          if (!settings.hasCardCharge || (settings.hasCardCharge && alreadyAccepted)) {
            return { name: 'Payins' };
          }
        }
      }
    },
    {
      path: 'recebiveis',
      component: PayinsPage,
      name: 'PayinsPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    },
    {
      path: 'links-de-pagamento',
      component: PaymentLinkListPage,
      name: 'PaymentLinkListPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    },
    {
      path: 'links-de-pagamento/novo',
      component: PaymentLinkPage,
      name: 'PaymentLinkPage',
      meta: {
        beforeEnter: [termsOfUseGuard]
      }
    },
    {
      path: 'cartao-de-credito',
      component: CardReceivablesListPage,
      name: 'CardReceivablesListPage',
      meta: {
        beforeEnter: [termsOfUseGuard],
        isAllowed() {
          return settingsResource.get().then(settings => settings.hasCardCharge);
        }
      }
    }
  ].map(route => ({
    ...route,
    meta: {
      authAs: APPS.PAYMENTS.key,
      ...route.meta
    }
  }))
};

async function termsOfUseGuard() {
  const settings = await settingsResource.get();
  const { exists: alreadyAccepted } = await checkpointResource.existsInCompany(
    checkpoint.CREDIT_CARD_ACCEPTED_TERMS_OF_USE
  );

  if (settings.hasCardCharge && !alreadyAccepted && loggedUser.isAdmin()) {
    return { name: 'PayinTermsOfUsePage' };
  }
}
