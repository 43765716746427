export { CardReceivableStatus as CardReceivableStatus } from '@/commons/resources/cardPayin';
import { cardReceivablesResource } from '@/commons/resources/cardPayin';
import moment from '@transfeeradev/moment';

export const listCardReceivables = async ({ page, pageSize, statuses, search, startDate, endDate } = {}) => {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const response = await cardReceivablesResource.list({
    page,
    page_size: pageSize,
    status__in,
    search,
    receive_date__gte: moment(startDate).format('YYYY-MM-DD'),
    receive_date__lte: moment(endDate).format('YYYY-MM-DD')
  });

  return {
    items: response.items,
    metadata: response.metadata
  };
};

export const getCardReceivablesSummary = async ({ page, pageSize, statuses, search, startDate, endDate } = {}) => {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const response = await cardReceivablesResource.getSummary({
    page,
    page_size: pageSize,
    status__in,
    search,
    receive_date__gte: moment(startDate).format('YYYY-MM-DD'),
    receive_date__lte: moment(endDate).format('YYYY-MM-DD')
  });

  return response;
};

export async function createReport({ receiveDateGte, receiveDateLte, statuses, search }) {
  const status__in = Object.entries(statuses)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const res = await cardReceivablesResource.exportCSV({
    receive_date__gte: moment(receiveDateGte).format('YYYY-MM-DD'),
    receive_date__lte: moment(receiveDateLte).format('YYYY-MM-DD'),
    status__in,
    search
  });

  return res.id;
}

export function getReportById(id) {
  return cardReceivablesResource.getReportById(id);
}
