<template>
  <table-page
    v-model:search-term="searchTerm"
    v-model:selected-filters="selectedFilters"
    title="Seus recebimentos"
    :loading="isSummaryLoading"
    search-placeholder="Pagador, valor ou ID (linha digitável, e2eId, ou código de autorização)"
    @clean-filter="onCleanFilter"
    @submit-filters="onSubmitFilters"
    @filter-by-period="onSearchByDatePeriod"
  >
    <template #header-right>
      <bg-button @click="onNavigateToPaymentLink">
        Criar Link de Pagamento
      </bg-button>
    </template>

    <template #header>
      <payin-summary
        :authorized-amount="payinsSummary?.authorized"
        :canceled-amount="payinsSummary?.canceled"
        :pending-amount="payinsSummary?.pending"
        :received-amount="payinsSummary?.received"
        :unauthorized-amount="payinsSummary?.unauthorized"
      />
    </template>

    <template #toolbar-buttons>
      <payin-export-button
        :statuses="selectedFilters?.status"
        :payment-types="selectedFilters?.paymentMethod"
        :search="searchTerm"
        :created-at-gte="period.startDate.toISOString()"
        :created-at-lte="period.endDate.toISOString()"
      />
    </template>

    <template #bg-filters>
      <bg-filter
        key="status"
        label="Status"
      >
        <bg-filter-option
          v-for="(statusName, statusKey) in payinStatusFilter"
          :key="statusKey"
          :label="statusName"
        />
      </bg-filter>
      <bg-filter
        key="paymentMethod"
        label="Método"
      >
        <bg-filter-option
          v-for="(name, key) in paymentMethodNames"
          :key="key"
          :label="name"
        />
      </bg-filter>
    </template>

    <template #content>
      <payins-table
        :is-end-date-out-of-bounds="isEndDateOutOfBounds"
        :payins="payins"
        :on-open-refund-dialog="openRefundDialog"
        :on-open-details-dialog="openDetailsDialog"
      />
      <payin-details-dialog
        ref="detailsDialog"
        @refund-click="openRefundDialog"
      />
      <payin-refund-dialog
        :id="refundShape.id"
        ref="refundDialog"
        :value="refundShape.value"
        @save="onRefund"
      />
      <tr-numeric-pagination
        class="payins-pagination"
        :current-page="pagination.currentPage"
        :page-size="pagination.itemsPerPage"
        :total="pagination.totalItems"
        :on-current-page-change="onPageChange"
      />
    </template>
  </table-page>
</template>

<script setup>
import { ref, watch } from 'vue';
import TablePage from '@/commons/components/presentational/TablePage.vue';
import PayinsTable from '@/apps/payin/payins/components/PayinsTable.vue';
import PayinRefundDialog from '@/apps/payin/payins/components/PayinRefundDialog.vue';
import PayinSummary from '@/apps/payin/payins/components/PayinSummary.vue';
import PayinDetailsDialog from '@/apps/payin/payins/components/PayinDetailsDialog.vue';
import moment from '@transfeeradev/moment/src/plugins/moment-business-day';
import PayinExportButton from '@/apps/payin/payins/components/PayinExportButton.vue';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { useTour } from '@/commons/composables/useTour';
import { payinStatusFilter } from '@/apps/payin/payins/constants/payinStatusFilter';
import { paymentMethodNames } from '@/apps/payin/payins/constants/paymentMethod';
import { BgFilter, BgFilterOption, BgButton } from '@transfeeradev/bridge';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { listPayins } from '@/apps/payin/payins/services/payins';
import { getSummary } from '@/apps/payin/payins/services/summary';
import { captureException } from '@sentry/vue';
import { usePolling } from '@/commons/composables/usePolling';
import { payinStatus } from '../constants/payinStatus';
import { useRouter } from 'vue-router';
import { useDebounceFn, watchOnce } from '@vueuse/core';

const router = useRouter();
const { handleError } = useErrorHandler();

const emit = defineEmits(['loading']);

const refundDialog = ref();
const detailsDialog = ref();
/** @type {payins: Payin[], metadata: {pagination: {itemsPerPage: number, totalItems: number}}}} */
const payins = ref([]);
/** @type {PayinsSummary} */
const payinsSummary = ref({});

const pagination = ref({ totalItems: null, itemsPerPage: 10, currentPage: 0 });
const searchTerm = ref();
const isEndDateOutOfBounds = ref(false);
const isSummaryLoading = ref(true);
const selectedFilters = ref({
  status: {},
  paymentMethod: {}
});

const openRefundDialog = payinData => {
  refundDialog.value.open(payinData);
};

const openDetailsDialog = id => {
  detailsDialog.value.open(id);
};

const closeDetailsDialog = () => {
  detailsDialog.value.close();
};

const onNavigateToPaymentLink = () => {
  checkpointResource.create(checkpoint.PAYMENT_LINK_ACCESSED_THROUGH_PAYINS);
  router.push({ name: 'PaymentLinkPage' });
};

const period = ref({
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month')
});

const onSearchByDatePeriod = (startDate, endDate) => {
  pagination.value.currentPage = 0;
  period.value = {
    startDate,
    endDate
  };
};

const refundShape = {
  id: '',
  value: ''
};

const onPageChange = page => {
  pagination.value.currentPage = page;
};

const onSubmitFilters = () => {
  pagination.value.currentPage = 0;
  checkRefundingStatus();
  fetchSummary();
};

const onCleanFilter = () => {
  selectedFilters.value.status = {};
  selectedFilters.value.paymentMethod = {};
};

const onRefund = () => {
  checkRefundingStatus();
  closeDetailsDialog();
};

const setupTour = async () => {
  const { defineSteps } = useTour({
    checkpointId: checkpoint.PAYIN_DASHBOARD_TOUR_VIEWED,
    elementSelectors: [
      '.payins-summary__container',
      'table > tbody > tr:nth-child(1) > td div > button[aria-label="Devolver"]',
      'table > tbody > tr:nth-child(1) > td div > button[aria-label="Ver mais"]'
    ]
  });

  defineSteps(
    ([step1El, step2El, step3El]) => [
      {
        element: step1El,
        title: 'Visão geral dos status',
        intro: 'Tenha uma visão geral de cada status dos seus recebimentos: recebidos, pendentes e cancelados.'
      },
      {
        element: step2El,
        title: 'Devolução Simples',
        intro: 'Faça um reembolso integral ou parcial para quem te pagou.'
      },
      {
        element: step3El,
        title: 'Veja mais detalhes',
        intro: 'Obtenha informações detalhadas sobre cada recebível para um gerenciamento mais completo.'
      }
    ],
    {
      doneLabel: 'Finalizar'
    }
  ).start();
};

const fetchPayins = async () => {
  try {
    const response = await listPayins({
      page: pagination.value.currentPage,
      pageSize: pagination.value.itemsPerPage,
      statuses: selectedFilters.value.status,
      paymentTypes: selectedFilters.value.paymentMethod,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });

    payins.value = response.payins;
    pagination.value.itemsPerPage = response.metadata.pagination.itemsPerPage;
    pagination.value.totalItems = response.metadata.pagination.totalItems;
    isEndDateOutOfBounds.value = response.isEndDateOutOfBounds;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os recebimentos');
  } finally {
    emit('loading', false);
  }
};

watchOnce(
  () => payins.value,
  () => {
    if (payins.value.length > 0) {
      setupTour().catch(captureException);
    }
  }
);

const fetchSummary = useDebounceFn(async () => {
  isSummaryLoading.value = true;
  try {
    const response = await getSummary({
      statuses: selectedFilters.value.status,
      paymentTypes: selectedFilters.value.paymentMethod,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });
    payinsSummary.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os totalizadores');
  } finally {
    isSummaryLoading.value = false;
  }
}, 300);

const { startPolling, stopPolling, pollingError } = usePolling({
  intervalInSeconds: 5
});

const checkRefundingStatus = useDebounceFn(() => {
  stopPolling();

  startPolling(fetchPayins, {
    immediate: true,
    keepPollingWhile: () => payins.value.some(payin => payin.status === payinStatus.REFUNDING)
  });
}, 300);

watch(
  [() => selectedFilters.value, () => period.value],
  () => {
    checkRefundingStatus();
    fetchSummary();
  },
  {
    deep: true
  }
);

watch([() => pagination.value.currentPage], () => {
  checkRefundingStatus();
});

watch(pollingError, error => {
  if (error) {
    stopPolling();
    handleError(error);
  }
});

checkRefundingStatus();
fetchSummary();
</script>

<style scoped>
.payins-pagination {
  margin-top: var(--bg-spacing-sm) !important;
}
</style>
