<template>
  <template v-if="receivedAt && paymentMethod !== paymentMethods.creditCard">
    {{ receivedAtFormatted }}
  </template>
  <template v-else>
    ---
  </template>
</template>

<script setup>
import formatDateTimeShape from '@/apps/payin/payins/constants/formatDateTimeShape';
import { paymentMethods } from '@/apps/payin/payins/constants/paymentMethod';
import { formatDateTime } from '@/commons/formatters/formatDate';

const props = defineProps({
  receivedAt: {
    type: String
  },
  paymentMethod: {
    type: String,
    required: true
  }
});

const receivedAtFormatted = formatDateTime(props.receivedAt, formatDateTimeShape.date);
</script>
