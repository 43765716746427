<template>
  <bg-badge
    :color="theme.badgeColor"
    :size="size"
  >
    <bg-icon
      v-if="icon"
      size="sm"
      :name="icon"
      :color="theme.textColor"
      :class="$style[size]"
    />
    <bg-text
      type="label"
      weight="semi-bold"
      :color="theme.textColor"
    >
      {{ text }}
    </bg-text>
  </bg-badge>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<script setup>
import { BgIcon, BgBadge, BgText } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: value => ['yellow', 'red', 'green', 'grey', 'blue'].includes(value)
  },
  size: {
    type: String,
    default: 'sm'
  },
  text: {
    type: String,
    required: true
  },
  icon: {
    type: String
  }
});

const theme = computed(() => {
  return {
    green: { textColor: 'green-semi-dark', badgeColor: 'green-light' },
    yellow: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light' },
    grey: { textColor: 'grey-semi-dark', badgeColor: 'grey-light' },
    blue: { textColor: 'blue-semi-dark', badgeColor: 'blue-light' },
    red: { textColor: 'red-semi-dark', badgeColor: 'red-light' }
  }[props.type];
});
</script>

<style scoped module>
.sm {
  width: 8px;
  margin-right: 4px;
}
</style>
