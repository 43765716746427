<template>
  <div
    id="app-notification"
    ref="notification"
  />
  <div class="app-wrapper">
    <bg-topbar
      class="app-topbar"
      :footer-menu-items="visibleFooterMenuItems"
      :profile-menu-item-groups="visibleProfileMenuItemGroups"
      :profile-full-name="loggedUser?.name || '?'"
    >
      <template #right>
        <top-bar-container />
      </template>
    </bg-topbar>
    <sidebar class="app-sidebar" />
    <transition name="fade">
      <main-loading v-if="isLoading" />
    </transition>
    <div class="app-content">
      <div class="app-container">
        <router-view v-slot="{ Component }">
          <div :class="{ hidden: isLoading }">
            <card-banner />
            <component
              :is="Component"
              @loading="handleLoading"
            />
          </div>
        </router-view>
      </div>
    </div>
    <blocked-user-error-dialog ref="blockedUserErrorDialog" />
  </div>
</template>

<script>
import MainLoading from '@/commons/components/presentational/MainLoading.vue';
import BlockedUserErrorDialog from '@/commons/components/presentational/BlockedUserErrorDialog.vue';
import Sidebar from './Sidebar.vue';
import CardBanner from '@/apps/payin/payins/components/CardBanner.vue';
import { authenticate } from '@/commons/services/auth';
import sentry from '@/commons/services/sentry';
import loggedUserResource from '@/commons/resources/loggedUser';
import { companyStatus, roles } from '@transfeeradev/api-enums';
import { BgIcon, BgSidebar, BgSidebarGroup, BgSidebarItem, BgTopbar } from '@transfeeradev/bridge';
import appRoles from '@transfeeradev/api-enums/roles/appRoles';
import { useAllowedRoute } from '@/commons/composables/useAllowedRoute';
import TopBarContainer from '@/apps/payments/components/container/TopBarContainer.vue';
import { APPS } from '@/commons/constants/apps';
import { useResizeObserver } from '@vueuse/core';

export default {
  components: {
    Sidebar,
    MainLoading,
    BlockedUserErrorDialog,
    BgSidebar,
    BgSidebarGroup,
    BgSidebarItem,
    BgIcon,
    BgTopbar,
    CardBanner,
    TopBarContainer
  },
  data: () => {
    return {
      APPS,
      footerMenuItems: [
        {
          label: 'Sair',
          to: { path: '/logout' },
          show: true
        }
      ],
      profileMenuItemGroups: [
        {
          label: 'Configurações',
          items: [
            {
              label: 'Minha empresa',
              to: { name: 'Admin' },
              show: false
            },
            {
              label: 'Minha conta',
              to: { name: 'MyAccount' },
              show: false
            },
            {
              label: 'Desenvolvedor',
              to: { name: 'DeveloperArea' },
              show: false
            }
          ]
        },
        {
          label: 'Ajuda',
          items: [
            {
              label: 'Status page',
              to: 'https://status.transfeera.com/'
            }
          ]
        }
      ],
      loggedUser: null,
      isLoading: true,
      appRoles
    };
  },
  computed: {
    visibleFooterMenuItems() {
      return this.footerMenuItems.filter(item => item.show);
    },
    visibleProfileMenuItemGroups() {
      return this.profileMenuItemGroups.map(group => ({
        ...group,
        items: group.items.filter(item => item.show)
      }));
    }
  },
  created() {
    authenticate(this.$router);
    sentry.setup(this.$.appContext.app);
    this.checkMenuItems();
  },
  mounted() {
    this.loggedUser = loggedUserResource.get();

    const isAdmin = this.loggedUser.profile === roles.appRoles.ADMIN.value;
    const isBlocked = this.loggedUser.company.status === companyStatus.BLOCKED;

    if (isAdmin && isBlocked) {
      this.$refs.blockedUserErrorDialog.open();
    }

    useResizeObserver(this.$refs.notification, entries => {
      document.documentElement.style.setProperty('--notification-height', entries[0].contentRect.height + 'px');
    });
  },
  methods: {
    handleLoading(isLoading) {
      this.isLoading = isLoading;
    },
    checkMenuItems() {
      this.footerMenuItems.forEach(item => {
        item.show = useAllowedRoute(item.to.name).show;
      });

      this.profileMenuItemGroups.forEach(group => {
        group.items.forEach(item => {
          item.show = useAllowedRoute(item.to.name).show;
        });
      });
    }
  }
};
</script>

<style>
#app {
  position: relative;
  display: grid;
  grid-template-areas:
    'notification'
    'wrapper';
}
</style>

<style scoped>
.app-wrapper {
  grid-area: wrapper;
  display: grid;
  grid-template-areas:
    'notification'
    'topbar'
    'content'
    'sidebar';
}

.app-topbar {
  grid-area: topbar;
}

.app-sidebar {
  grid-area: sidebar;
}

#app-notification {
  grid-area: notification;
}

.app-content {
  grid-area: content;
  background-color: var(--bg-bg-background-alt);
  height: calc(100vh - 64px - var(--notification-height));
  overflow: auto;
}

.app-container {
  margin: auto;
  max-width: calc(1170px + 64px);
  padding: 0 32px;
}

@media (min-width: 500px) {
  .app-wrapper {
    grid-template-columns: fit-content(100%) 1fr;
    grid-template-rows: fit-content(100%) 1fr;
    grid-template-areas:
      'sidebar topbar'
      'sidebar content';
  }
}
</style>
