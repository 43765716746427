const DEFAULT_MAX_LENGTH = 10;

export const truncateString = (string = '', length = DEFAULT_MAX_LENGTH) => {
  return `${string.substring(0, length)}${string.length > length ? '...' : ''}`;
};

export const middleTruncateWithEllipsis = (string = '', maxLength = DEFAULT_MAX_LENGTH) => {
  if (string.length <= length) {
    return string;
  }

  // remove x caracthers from the center until the string fits the maxLength
  const halfLength = Math.floor(maxLength / 2);
  const firstHalf = string.slice(0, halfLength);
  const secondHalf = string.slice(-halfLength);
  const truncatedString = `${firstHalf}...${secondHalf}`;

  return truncatedString;
};
