<template>
  <table-page
    v-model:search-term="searchTerm"
    v-model:selected-filters="selectedFilters"
    search-placeholder="Código de autorização ou valor"
    title="Cartão de crédito"
    :loading="isSummaryLoading"
    @clean-filter="onCleanFilter"
    @submit-filters="onSubmitFilters"
    @filter-by-period="onSearchByDatePeriod"
  >
    <template #toolbar-buttons>
      <card-receivables-export-button
        :statuses="selectedFilters?.status"
        :search="searchTerm"
        :receive-date-gte="period.startDate.toISOString()"
        :receive-date-lte="period.endDate.toISOString()"
      />
    </template>

    <template #header>
      <summary-cards>
        <summary-card
          label="A receber"
          icon="calendar_today"
          color="yellow"
          :amount="summary.scheduled?.total_amount"
        />
        <summary-card
          label="Recebidos"
          icon="check-price"
          color="green"
          :amount="summary.received?.total_amount"
        />
        <summary-card
          label="Cancelados"
          icon="cancel"
          color="grey"
          :amount="summary.canceled?.total_amount"
        />
      </summary-cards>
    </template>

    <template #bg-filters>
      <bg-filter
        key="status"
        label="Status"
      >
        <bg-filter-option
          v-for="(statusName, statusKey) in statusFilter"
          :key="statusKey"
          :label="statusName"
        />
      </bg-filter>
    </template>

    <template #content>
      <tr-table disable-click>
        <thead>
          <tr-tr>
            <tr-th width="12%">
              Status
            </tr-th>
            <tr-th width="10%">
              Transação
            </tr-th>
            <tr-th width="10%">
              Recebimento
            </tr-th>
            <tr-th width="15%">
              Cod. de autorização
            </tr-th>
            <tr-th width="10%">
              Parcela
            </tr-th>
            <tr-th width="15%">
              Valor bruto
            </tr-th>
            <tr-th width="15%">
              Desconto
            </tr-th>
            <tr-th width="25%">
              Valor líquido
            </tr-th>
          </tr-tr>
        </thead>
        <tbody>
          <tr-tr
            v-for="receivable in receivables"
            :key="receivable.id"
          >
            <tr-td>
              <card-receivable-status-badge :status="receivable.status" />
            </tr-td>
            <tr-td>
              {{ formatDateTime(receivable.transaction_date) }}
            </tr-td>
            <tr-td>
              <template v-if="receivable.receive_date">
                {{ formatDateTime(receivable.receive_date) }}
              </template>
              <template v-else-if="receivable.expected_receive_date">
                {{ formatDateTime(receivable.expected_receive_date) }}
              </template>
              <template v-else>
                ---
              </template>
            </tr-td>
            <tr-td>
              {{ receivable.authorization_code }}
            </tr-td>
            <tr-td>
              {{ receivable.current_installment }}/{{ receivable.total_installment }}
            </tr-td>
            <tr-td>
              {{ centsToReal(receivable.gross_amount) }}
            </tr-td>
            <tr-td>
              {{ centsToReal(receivable.deduction_amount) }}
            </tr-td>
            <tr-td>
              {{ centsToReal(receivable.net_amount) }}
            </tr-td>
          </tr-tr>
        </tbody>
      </tr-table>
      <tr-numeric-pagination
        class="payins-pagination"
        :current-page="pagination.currentPage"
        :page-size="pagination.itemsPerPage"
        :total="pagination.totalItems"
        :on-current-page-change="onPageChange"
      />
    </template>
  </table-page>
</template>

<script setup>
import { ref, watch } from 'vue';
import TablePage from '@/commons/components/presentational/TablePage.vue';
import { BgFilter, BgFilterOption } from '@transfeeradev/bridge';
import SummaryCards from '@/commons/components/presentational/SummaryCards.vue';
import SummaryCard from '@/commons/components/presentational/SummaryCard.vue';
import moment from '@transfeeradev/moment';
import { useDebounceFn } from '@vueuse/core';
import { listCardReceivables, getCardReceivablesSummary } from '@/apps/payin/card-receivables/services/cardReceivables';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { centsToReal } from '@/commons/formatters/currency';
import { formatDateTime } from '@/commons/formatters/formatDate';
import CardReceivableStatusBadge from '@/apps/payin/card-receivables/components/CardReceivableStatusBadge.vue';
import CardReceivablesExportButton from '@/apps/payin/card-receivables/components/CardReceivablesExportButton.vue';

const { handleError } = useErrorHandler();

const emit = defineEmits(['loading']);
const receivables = ref([]);
const summary = ref({});

const pagination = ref({ totalItems: null, itemsPerPage: 10, currentPage: 0 });
const searchTerm = ref();

const statusFilter = ref({
  scheduled: 'A receber',
  received: 'Recebidos',
  canceled: 'Cancelados'
});

const isSummaryLoading = ref(true);
const selectedFilters = ref({
  status: {}
});

const period = ref({
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month')
});

const onSearchByDatePeriod = (startDate, endDate) => {
  pagination.value.currentPage = 0;
  period.value = {
    startDate,
    endDate
  };
};
const onPageChange = page => {
  pagination.value.currentPage = page;
};

const onSubmitFilters = () => {
  pagination.value.currentPage = 0;
  fetchReceivables();
  fetchSummary();
};

const onCleanFilter = () => {
  selectedFilters.value.status = {};
  fetchReceivables();
  fetchSummary();
};

const fetchReceivables = useDebounceFn(async () => {
  try {
    const response = await listCardReceivables({
      page: pagination.value.currentPage,
      pageSize: pagination.value.itemsPerPage,
      statuses: selectedFilters.value.status,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });

    receivables.value = response.items;
    pagination.value.itemsPerPage = response.metadata.pagination.itemsPerPage;
    pagination.value.totalItems = response.metadata.pagination.totalItems;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os recebíveis');
  } finally {
    emit('loading', false);
  }
}, 300);

const fetchSummary = useDebounceFn(async () => {
  isSummaryLoading.value = true;
  try {
    const response = await getCardReceivablesSummary({
      statuses: selectedFilters.value.status,
      search: searchTerm.value,
      startDate: period.value.startDate,
      endDate: period.value.endDate
    });

    summary.value = response;
  } catch (e) {
    handleError(e, 'Ocorreu um erro ao buscar os totalizadores');
  } finally {
    isSummaryLoading.value = false;
  }
}, 300);

watch([() => statusFilter.value, () => period.value], () => {
  fetchReceivables();
  fetchSummary();
});

watch([() => pagination.value.currentPage], () => {
  fetchReceivables();
});

fetchReceivables();
fetchSummary();
</script>

<style scoped>
.payins-pagination {
  margin-top: var(--bg-spacing-sm) !important;
}
</style>
