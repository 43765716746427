<template>
  <tr-text-field
    ref="field"
    :label="label"
    classes="col-lg-3"
    type="date"
    name="paymentDate"
    :disable-past-dates="true"
    datepicker-orientation="bottom"
    :error-message="errorMessage"
    required="true"
    :no-margin="noMargin"
    :custom-validation="validate"
    :model-value="billet.payment_date"
    :on-value-change="onChange"
  />
</template>

<script>
import { PaymentDate } from '@transfeeradev/billet-validation';
import DateService from '@transfeeradev/api-services/DateService';
import { getMessage } from '@/commons/services/error';

const DEFAULT_ERROR_MESSAGE = 'Data de pagamento inválida';

export default {
  name: 'billet-payment-date-field-container',
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    label: {
      required: false,
      type: String,
      default: 'Agendar para'
    },
    onChange: {
      required: true,
      type: Function
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    validationResult: {}
  }),
  computed: {
    errorMessage() {
      const isInvalid = this._isInvalid();
      if (isInvalid) {
        return this.validationResult.errorMessage;
      }
      return DEFAULT_ERROR_MESSAGE;
    }
  },
  watch: {
    billet() {
      this.validate();
    }
  },
  mounted() {
    const result = this.validate();
    if (!result && this.barcode) {
      this.$refs.field.forceValidation();
    }
  },
  methods: {
    validate() {
      const billet = Object.assign({}, this.billet);
      billet.payment_date = DateService.getFormatted(billet.payment_date);

      if (!billet.barcode || !billet.payment_date) {
        return true;
      }

      const validation = new PaymentDate(billet, { disallowOverdue: true }).validate();

      if (!validation.isValid) {
        this.validationResult = {
          isValid: false,
          errorMessage: getMessage(validation.errorMessage)
        };
        return false;
      }

      return true;
    },
    _isInvalid() {
      return Boolean(this.validationResult && !this.validationResult.isValid);
    }
  }
};
</script>
