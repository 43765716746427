<template>
  <tr-text-field
    ref="field"
    :classes="classes"
    name="pixDescription"
    placeholder="Escreva uma mensagem..."
    :custom-validation="validate"
    :error-message="errorMessage"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #label>
      <span>Descrição Pix (opcional)</span>
      <tr-svg-icon
        v-tooltip.top-center="'Envie uma mensagem para o recebedor'"
        class="input-label-icon"
        color="blue"
        name="ic-info-outline"
      />
    </template>
  </tr-text-field>
</template>

<script>
import dompurify from 'dompurify';

export default {
  name: 'transfer-pix-description-field-container',
  props: ['classes', 'modelValue'],
  emits: ['update:modelValue'],
  data: () => ({
    validationResult: {}
  }),
  computed: {
    errorMessage() {
      if (this.validationResult && !this.validationResult.isValid) {
        return this.validationResult.errorMessage;
      }

      return '';
    }
  },
  async mounted() {
    await this.validate();
    this.$refs.field.forceValidation();
  },
  methods: {
    validate() {
      return new Promise(resolve => {
        if (!this.modelValue) {
          this.validationResult = { isValid: true };
          return resolve(true);
        }

        if (this.modelValue.length > 140) {
          this.validationResult = this._createError('Tamanho máximo do campo é 140 caracteres');
          return resolve(false);
        }

        const htmlOptions = {
          ALLOWED_TAGS: ['b', 'i', 'em', 'strong']
        };

        if (dompurify.sanitize(this.modelValue, htmlOptions) !== this.modelValue) {
          this.validationResult = this._createError('Descrição Pix não permite código html inseguro');
          return resolve(false);
        }

        const invalidCharacters = this.modelValue.match(/[^\x00-\xFF]+/g); // eslint-disable-line

        if (invalidCharacters) {
          this.validationResult = this._createError('Descrição Pix contém caracteres inválidos');
          return resolve(false);
        }

        this.validationResult = { isValid: true };
        return resolve(true);
      });
    },
    _createError(message) {
      return {
        isValid: false,
        errorMessage: message
      };
    }
  }
};
</script>
