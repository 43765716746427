export const payinStatus = {
  CANCELED: 'canceled',
  CANCELED_BY_USER: 'canceled_by_user',
  CANCELED_BY_PSP: 'canceled_by_psp',
  PARTIALLY_REFUNDED: 'partially_refunded',
  PENDING: 'pending',
  RECEIVED: 'received',
  REFUNDED: 'refunded',
  REFUNDING: 'refunding',
  AUTHORIZED: 'authorized',
  UNAUTHORIZED: 'unauthorized'
};
