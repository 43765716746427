<template>
  <main class="page-wrapper">
    <tr-page-header :title="title">
      <template
        v-if="onFilterByPeriod"
        #center-filter
      >
        <div class="row indicator-page__date-filter">
          <div class="col-lg-10">
            <tr-month-picker-button :on-filter="onFilterByPeriod" />
          </div>
        </div>
      </template>
      <template #right-filter>
        <slot name="header-right" />
      </template>
    </tr-page-header>
    <tr-loading
      v-if="loading"
      classes="center-h-and-v width-100"
    />
    <template v-else>
      <slot name="header" />
      <div class="toolbar">
        <div class="page-filters">
          <bg-filters
            v-if="selectedFilters"
            v-model="selectedFilters"
            :label="`Filtros ${filtersCount ? '(' + filtersCount + ')' : ''}`"
            @update:modelValue="e => emit('submit-filters', e)"
          >
            <slot name="bg-filters" />
          </bg-filters>
          <tr-form
            name="searchForm"
            :on-valid-submit="(...e) => emit('submit-filters', ...e)"
          >
            <tr-text-field
              v-model="searchTerm"
              v-tooltip="searchPlaceholder"
              name="search"
              classes="col-lg-12"
              no-margin="true"
              :placeholder="searchPlaceholder"
              input-search="true"
            />
          </tr-form>
        </div>
        <slot name="toolbar-buttons" />
      </div>
      <button
        v-if="filtersCount"
        class="reset-button page-reset-filters-button"
        @click="onCleanFilter"
      >
        <bg-text
          is="u"
          weight="bold"
          type="body-sm"
        >
          Limpar filtros
        </bg-text>
      </button>
      <slot name="content" />
    </template>
  </main>
</template>

<script setup>
import { BgText, BgFilters } from '@transfeeradev/bridge';
import { useSlots } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  loading: {
    type: Boolean
  },
  onSubmitFilters: {
    type: Function
  },
  onFilterByPeriod: {
    type: Function
  },
  onCleanFilter: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  searchPlaceholder: {
    type: String,
    default: 'Buscar'
  }
});

const slots = useSlots();

if (slots['bg-filters'] && !props.onCleanFilter) {
  throw new Error('You must provide a onCleanFilter function when using the bg-filters slot');
}

const emit = defineEmits(['submit-filters', 'filter-by-period']);
const searchTerm = defineModel('searchTerm', { type: String });
const selectedFilters = defineModel('selectedFilters', { type: Object, required: true });

const filtersCount = computed(() => {
  return Object.values(selectedFilters.value || {})
    .map(values => Object.values(values).filter(selected => selected).length)
    .reduce((acc, curr) => acc + curr, 0);
});
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  margin: var(--bg-spacing-md);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-filters {
  display: flex;
  align-items: flex-end;
  margin-top: var(--bg-spacing-lg);
}

.page-reset-filters-button {
  width: fit-content;
  color: var(--bg-blue-normal);
  margin-top: var(--bg-spacing-sm);
}

.page-summary__container {
  display: flex;
  flex-direction: row;
  gap: var(--bg-spacing-sm);
}

.page-summary__container :deep(.bg-card) {
  box-shadow: none;
}

.page-pagination {
  margin-top: var(--bg-spacing-sm) !important;
}
</style>
