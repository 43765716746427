<template>
  <summary-cards>
    <summary-card
      :amount="receivedAmount"
      color="green"
      icon="check-price"
      label="Recebidos"
    />
    <summary-card
      :amount="authorizedAmount"
      color="green"
      icon="calendar_today"
      label="A receber"
      :disabled="!hasCardCharge"
      :description="!hasCardCharge ? 'Fale com a suporte e habilite cartão de crédito para impulsionar suas vendas.' : ''"
    />
    <summary-card
      :amount="pendingAmount"
      color="yellow"
      icon="clock"
      label="Pendentes"
    />
    <summary-card
      :amount="canceledAmount"
      color="grey"
      icon="cancel"
      label="Cancelados"
    />
    <summary-card
      :amount="unauthorizedAmount"
      color="red"
      icon="error"
      label="Negados"
    />
  </summary-cards>
</template>

<script setup>
import SummaryCard from '@/commons/components/presentational/SummaryCard.vue';
import SummaryCards from '@/commons/components/presentational/SummaryCards.vue';
import settingsResource from '@/apps/payments/resources/settings';
import { computed, ref } from 'vue';
import { captureException } from '@sentry/vue';

defineProps({
  receivedAmount: {
    type: Number,
    required: true,
    default: 0
  },
  authorizedAmount: {
    type: Number,
    required: true,
    default: 0
  },
  pendingAmount: {
    type: Number,
    required: true,
    default: 0
  },
  canceledAmount: {
    type: Number,
    required: true,
    default: 0
  },
  unauthorizedAmount: {
    type: Number,
    required: true,
    default: 0
  }
});

const settings = ref();

const hasCardCharge = computed(() => settings.value?.hasCardCharge);

const getSettings = async () => {
  try {
    const response = await settingsResource.get();
    settings.value = response;
  } catch (error) {
    captureException(error);
  }
};

getSettings();
</script>
