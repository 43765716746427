<template>
  <bg-menu>
    <template #button="args">
      <bg-button
        v-bind="args"
        :disabled="disabled"
        size="sm"
        right-icon="caret-down"
      >
        {{ disabled ? 'Exportando...' : 'Exportar' }}
      </bg-button>
    </template>
    <bg-menu-item @click="onClick">
      Relatório CSV
    </bg-menu-item>
  </bg-menu>
</template>

<script setup>
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { createReport, getReportById } from '@/apps/payin/payins/services/payins';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { usePolling } from '@/commons/composables/usePolling';
import { useConfirmLeave } from '@/commons/composables/useConfirmLeave';
import { downloadFile } from '@/commons/services/files';
import { BgButton, BgMenu, BgMenuItem } from '@transfeeradev/bridge';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  statuses: {
    type: Object
  },
  paymentTypes: {
    type: Object
  },
  search: {
    type: String
  },
  createdAtGte: {
    type: String,
    required: true
  },
  createdAtLte: {
    type: String,
    required: true
  }
});

const isExporting = ref(false);
const { handleError } = useErrorHandler();
const { startPolling, stopPolling, isPolling, pollingError } = usePolling({
  intervalInSeconds: 2
});

const disabled = computed(() => isExporting.value || isPolling.value);

const onClick = async () => {
  try {
    isExporting.value = true;

    const id = await createReport({
      statuses: props.statuses,
      paymentTypes: props.paymentTypes,
      search: props.search,
      createdAtGte: props.createdAtGte,
      createdAtLte: props.createdAtLte
    });

    startPolling(() => getReportById(id), {
      immediate: true,
      keepPollingWhile: value => {
        const link = value?.link;

        if (link) {
          downloadFile(link, value.filename);
          checkpointResource.create(checkpoint.PAYIN_REPORT_DOWNLOADED);
        }

        return !link;
      }
    });
  } catch (error) {
    handleError(error);
  } finally {
    isExporting.value = false;
  }
};

watch(pollingError, error => {
  if (error) {
    stopPolling();
    handleError(error);
  }
});

useConfirmLeave({
  isDirty: disabled,
  onConfirm: stopPolling
});
</script>
