import { paymentMethodNames } from '@/apps/payin/payins/constants/paymentMethod';
import { paymentMethod as paymentMethods } from '@transfeeradev/api-enums/payinApi';
import { computed, toRef } from 'vue';

export const usePaymentMethod = paymentMethod => {
  const paymentMethodRef = toRef(paymentMethod);

  const paymentMethodMacro = computed(() => {
    return {
      [paymentMethods.pixKey]: 'pix',
      [paymentMethods.pixQrCodeStatic]: 'pix',
      [paymentMethods.pixQrCodeDynamic]: 'pix',
      [paymentMethods.chargeBoleto]: 'boleto',
      [paymentMethods.chargeBoletoQrCode]: 'pix',
      [paymentMethods.chargePixQrCodeDynamic]: 'pix',
      [paymentMethods.manual]: 'pix',
      [paymentMethods.creditCard]: 'credit_card'
    }[paymentMethodRef.value];
  });

  const paymentMethodName = computed(() => {
    return paymentMethodNames[paymentMethodRef.value];
  });

  return {
    paymentMethodName,
    paymentMethodMacro
  };
};
