import moment from '@transfeeradev/moment';
import { payinStatus } from '@/apps/payin/payins/constants/payinStatus';

const payinRefundButtonStatus = {
  notReceivedYet: 'Cobrança ainda não foi recebida',
  refundDeadlineExceeded: 'Tempo para devolução excedido',
  canceled: 'Cobrança cancelada',
  returned: 'Devolvido',
  refund: 'Devolver'
};

export const refundButtonStatus = payin => {
  if (payin.status === payinStatus.PENDING) {
    return payinRefundButtonStatus.notReceivedYet;
  }
  if (payin.status === payinStatus.CANCELED) {
    return payinRefundButtonStatus.canceled;
  }
  if (moment(payin.refund_expires_at).isAfter(moment())) {
    return payinRefundButtonStatus.refundDeadlineExceeded;
  }
  if (payin.status === payinStatus.RETURNED) {
    return payinRefundButtonStatus.returned;
  } else {
    return payinRefundButtonStatus.refund;
  }
};
