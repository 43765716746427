<template>
  <tr-text-field
    ref="field"
    :model-value="billet.description"
    :label="label"
    classes="col-lg-5"
    name="description"
    :required="true"
    :custom-validation="validate"
    :on-value-change="onChange"
    :error-message="errorMessage"
  />
</template>

<script>
const DEFAULT_ERROR_MESSAGE = 'O campo não pode ser vazio';

export default {
  name: 'billet-description-field-container',
  props: {
    billet: {
      required: false,
      type: Object,
      default: () => ({})
    },
    label: {
      required: false,
      type: String,
      default: 'Dê um nome ao boleto para identificá-lo mais facilmente'
    },
    onChange: {
      required: true,
      type: Function
    }
  },
  data: () => ({
    validationResult: {}
  }),
  computed: {
    errorMessage() {
      const isInvalid = this._isInvalid();
      if (isInvalid) {
        return this.validationResult.errorMessage;
      }
      return DEFAULT_ERROR_MESSAGE;
    }
  },
  watch: {
    billet() {
      this.validate();
    }
  },
  mounted() {
    const result = this.validate();
    if (!result && this.barcode) {
      this.$refs.field.forceValidation();
    }
  },
  methods: {
    validate() {
      const billet = Object.assign({}, this.billet);
      const isEmpty = !billet.description || billet.description.trim() === '';

      if (isEmpty) {
        this.validationResult = {
          isValid: false,
          errorMessage: DEFAULT_ERROR_MESSAGE
        };
        return false;
      }

      return true;
    },
    _isInvalid() {
      return Boolean(this.validationResult && !this.validationResult.isValid);
    }
  }
};
</script>
