<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="payin-refund-dialog"
    title="Devolver recebimento"
    :has-its-own-buttons="true"
    :on-close="close"
  >
    <template v-if="isFill">
      <bg-text
        type="body-md"
        color="grey-dark"
      >
        Você pode devolver o valor integral ou parcial para quem te pagou.
      </bg-text>
      <tr-text-field
        v-model="refundModelAmount"
        class="payin-refund-dialog__input"
        label="Quanto deseja devolver?"
        name="refundAmount"
        type="currency"
        placeholder="R$ 0,00"
        :required="true"
        error-message="Digite um valor para devolução"
      />
      <bg-text
        type="label"
        color="grey-semi-dark"
        class="payin-refund-dialog__input-foot-label"
      >
        Você pode devolver até {{ centsToReal(payin.refundable_amount) }}
      </bg-text>
    </template>
    <template v-else>
      <bg-text
        type="body-md"
        color="grey-dark"
      >
        Tem certeza que deseja devolver {{ $format.currency(refundModelAmount) }} para {{ payin.payer.name }}?
      </bg-text>
    </template>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="isFill ? close() : changeStep()"
      >
        Cancelar
      </bg-button>
      <bg-button
        size="md"
        class="pull-right"
        :disabled="(realToCents(refundModelAmount) > payin.refundable_amount || refundModelAmount <= 0)"
        @click="onSubmit"
      >
        {{ isFill ? 'Devolver' : 'Confirmar' }}
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';
import { useToaster } from '@/commons/composables/useToaster';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { computed, ref } from 'vue';
import { refund } from '@/apps/payin/payins/services/payins';
import { centsToReal, realToCents } from '@/commons/formatters/currency';

defineProps({
  onRefund: {
    type: Function
  }
});
const emit = defineEmits(['save']);

const toaster = useToaster();
const { handleError } = useErrorHandler();

const modal = ref();
const payin = ref({});
const refundModelAmount = ref(0);
const step = ref(true);
const isFill = computed(() => step.value);

const changeStep = () => {
  step.value = !step.value;
};

const open = payinData => {
  payin.value = payinData;
  modal.value.open(payinData);
};

const close = () => {
  refundModelAmount.value = 0;
  step.value = true;
  modal.value.close();
};

const onSave = async (id, amount) => {
  try {
    await refund(id, amount);
    close();
    emit('save');
    toaster.success('Valor devolvido com sucesso');
    checkpointResource.create(checkpoint.PAYIN_REFUNDED);
  } catch (e) {
    handleError(e, 'Ocorreu um erro na devolução de valor');
  }
};

const onSubmit = () => {
  isFill.value ? changeStep() : onSave(payin.value.id, refundModelAmount.value);
};

defineExpose({
  open
});
</script>

<style scoped>
.payin-refund-dialog__input {
  width: 100%;
  margin-top: var(--bg-spacing-md);
  margin-bottom: 0 !important;
}

.payin-refund-dialog__input-foot-label {
  display: inline-block;
  margin-top: var(--bg-spacing-x-sm);
  padding: 0 var(--bg-spacing-sm);
}
</style>
